/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/account/delete`, null, null, { requestHeaders: this.requestHeaders });
    }

    /*
    Unlike other edit APIs, we don't need to specify an id here because the account id
    is already in the path parameters (see constructor)
    */
    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/account/edit`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class BrandProfile {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/brandprofile`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/brandprofile`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/brandprofile`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/brandprofile`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/brandprofile`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrandProfileStatus {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/brandprofile-status`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(query) {
        return getJson(`${this.url}/state/brandprofile-status`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/brandprofile-status`, request, null, { requestHeaders: this.requestHeaders });
    }

    // NOTE: service admin only
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/brandprofile-status`, request, query, { requestHeaders: this.requestHeaders });
    }

    // NOTE: service admin only
    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/brandprofile-status`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/brandprofile-status`, query, { requestHeaders: this.requestHeaders });
    }
}

class Domain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async checkWhois(query) {
        return getJson(`${this.url}/check/domain-whois`, query, { requestHeaders: this.requestHeaders });
    }

    async checkNameservers(query) {
        return getJson(`${this.url}/check/domain-nameservers`, query, { requestHeaders: this.requestHeaders });
    }
}

class DomainStatus {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/domain-status`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(query) {
        return getJson(`${this.url}/state/domain-status`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/domain-status`, request, null, { requestHeaders: this.requestHeaders });
    }

    // NOTE: service admin only
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain-status`, request, query, { requestHeaders: this.requestHeaders });
    }

    // NOTE: service admin only
    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain-status`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/domain-status`, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailAudience {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-audience`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-audience`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-audience`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-audience`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-audience`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-audience`, query, { requestHeaders: this.requestHeaders });
    }
}

// this is a "link" collection associating contacts with members
class EmailAudienceContact {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/email-audience-contact`, request, query, { requestHeaders: this.requestHeaders });
    }

    // request should have { email_audience_id, email_contact_id }
    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-audience-contact`, request, null, { requestHeaders: this.requestHeaders });
    }

    // async get(id) {
    //     return getJson(`${this.url}/state/email-audience-contact`, { id }, { requestHeaders: this.requestHeaders });
    // }

    // async edit(query, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit/email-audience-contact`, request, query, { requestHeaders: this.requestHeaders });
    // }

    // query should have { email_audience_id, email_contact_id }
    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-audience-contact`, null, query, { requestHeaders: this.requestHeaders });
    }

    // can search for 1) email contacts in an audience given { email_audience_id }, or 2) email audiences for a contact given { email_contact_id }, or 3) whether a specific contact is in a specific audience, given { email_audience_id, email_contact_id }
    async search(query) {
        return getJson(`${this.url}/search/email-audience-contact`, query, { requestHeaders: this.requestHeaders });
    }
}

// this is a "link" collection associating contacts with topics, and also their current opt-in/opt-out status
class EmailContactPermit {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // request should have { email_contact_id, topic_id, permit (boolean) }
    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-contact-permit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/email-contact-permit`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-contact-permit`, request, query, { requestHeaders: this.requestHeaders });
    }

    // query should have { email_contact_id, topic_id }
    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-contact-permit`, null, query, { requestHeaders: this.requestHeaders });
    }

    // can search for 1) email contacts with status for a topic given { topic_id }, 2) topics and status for an email contact given { email_contact_id }, 3) optin/optout status for a specific contact and topic given { email_contact_id, topic_id }
    async search(query) {
        return getJson(`${this.url}/search/email-contact-permit`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailContact {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-contact`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-contact`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-contact`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-contact`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-contact`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-contact`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailContactDispatchEvent {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/email-contact-dispatch-event`, request, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/email-contact-dispatch-event`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-contact-dispatch-event`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailDispatch {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-dispatch`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-dispatch`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-dispatch`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-dispatch`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-dispatch`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-dispatch`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailDispatchStatus {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-dispatch-status`, request, { email_dispatch_id: id }, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-dispatch-status`, { email_dispatch_id: id }, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-dispatch-status`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailDispatchQueueItem {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/email-dispatch-queue-item`, request, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/email-dispatch-queue-item`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-dispatch-queue-item`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailTemplate {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // send test email for specified email template id, with specified sample data (request object)
    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-template`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-template`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-template`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-template`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-template`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-template`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailOrigin {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-origin`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-origin`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-origin`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-origin`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-origin`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-origin`, query, { requestHeaders: this.requestHeaders });
    }
}

class EmailReplyto {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/email-replyto`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/email-replyto`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/email-replyto`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/email-replyto`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/email-replyto`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/email-replyto`, query, { requestHeaders: this.requestHeaders });
    }
}

class OutboundEmailTransport {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/outbound-email-transport`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/outbound-email-transport`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/outbound-email-transport`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/outbound-email-transport`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/outbound-email-transport`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/outbound-email-transport`, query, { requestHeaders: this.requestHeaders });
    }
}

class OutboundEmailTransportType {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async search(query) {
        return getJson(`${this.url}/search/outbound-email-transport-type`, query, { requestHeaders: this.requestHeaders });
    }
}

class PostalAddress {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // async check(id, request) {
    //     return postJsonAcceptJson(`${this.url}/check/email-origin`, request, { id }, { requestHeaders: this.requestHeaders });
    // }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/postal-address`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/postal-address`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/postal-address`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/postal-address`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/postal-address`, query, { requestHeaders: this.requestHeaders });
    }
}

class Tag {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/tag`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/tag`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/tag`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/tag`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/tag`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/tag`, query, { requestHeaders: this.requestHeaders });
    }
}

class Topic {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/topic`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/topic`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/topic`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/topic`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/topic`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/topic`, query, { requestHeaders: this.requestHeaders });
    }
}

class Client {
    constructor(context = {}) {
        this.currentAccount = new CurrentAccount(context);
        this.brandProfile = new BrandProfile(context);
        this.brandProfileStatus = new BrandProfileStatus(context);
        this.domain = new Domain(context);
        this.domainStatus = new DomainStatus(context);
        this.emailAudience = new EmailAudience(context);
        this.emailAudienceContact = new EmailAudienceContact(context);
        this.emailContact = new EmailContact(context);
        this.emailContactDispatchEvent = new EmailContactDispatchEvent(context);
        this.emailContactPermit = new EmailContactPermit(context);
        this.emailDispatch = new EmailDispatch(context);
        this.emailDispatchStatus = new EmailDispatchStatus(context);
        this.emailDispatchQueueItem = new EmailDispatchQueueItem(context);
        this.emailTemplate = new EmailTemplate(context);
        this.emailOrigin = new EmailOrigin(context);
        this.emailReplyto = new EmailReplyto(context);
        this.outboundEmailTransport = new OutboundEmailTransport(context);
        this.outboundEmailTransportType = new OutboundEmailTransportType(context);
        this.postalAddress = new PostalAddress(context);
        this.tag = new Tag(context);
        this.topic = new Topic(context);
        this.linkAccountUser = new LinkAccountUser(context);
    }
}

export {
    CurrentAccount,
    BrandProfile,
    BrandProfileStatus,
    Client,
    Domain,
    DomainStatus,
    EmailAudience,
    EmailAudienceContact,
    EmailContact,
    EmailContactDispatchEvent,
    EmailContactPermit,
    EmailDispatch,
    EmailDispatchStatus,
    EmailDispatchQueueItem,
    EmailTemplate,
    EmailOrigin,
    EmailReplyto,
    OutboundEmailTransport,
    OutboundEmailTransportType,
    PostalAddress,
    Tag,
    Topic,
    LinkAccountUser,
};
