import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faUsers, faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faPlay as faPlaySolid,
    faPlusCircle as faPlusCircleSolid,
    faPaperPlane as faPaperPlaneSolid,
    faTag as faTagSolid,
    faRegistered as faRegisteredSolid,
    faMapMarker as faMapMarkerSolid,
    faGlobe as faGlobeSolid,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faBriefcase, faCompress, faDesktop, faShieldAlt, faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faCloudDownloadAlt, faUnlock,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faChartLine as faChartLineSolid,
    faFileCode as faFileCodeSolid,
    faFileAlt as faFileAltSolid,
    faFolder as faFolderSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faAt as faAtSolid,
    faBolt as faBoltSolid,
    faKey as faKeySolid,
    faIdBadge as faIdBadgeSolid,
    faAddressCard as faAddressCardSolid,
    faLock as faLockSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
    faRedo as faRedoSolid, // NOTE: in v6.0.0 this is renamed from 'redo' to 'arrow-rotate-right'
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare as faSquareRegular,
    faCheckSquare as faCheckSquareRegular,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
    faClock as faClockRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCalendarAlt as faCalendarAltRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
    faFileCheck as faFileCheckRegular,
    faClipboardListCheck as faClipboardListCheckRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCalendarEdit as faCalendarEditSolid,
    faCommentAltExclamation as faCommentAltExclamationSolid,
    faInboxIn as faInboxInSolid,
    faInboxOut as faInboxOutSolid,
    // faMailbox as faMailboxSolid,
} from '@fortawesome/pro-solid-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faUsers, faEnvelope, faPhone, faCheck, faFile, faCheckSquare, faSquareRegular,
        faCheckSquareRegular,
        faPlus, faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAlt,
        faPlaySolid,
        faCalendarEditSolid,
        faPlusCircleSolid,
        faPaperPlaneSolid,
        faTagSolid,
        faRegisteredSolid,
        faCommentAltExclamationSolid,
        faAtSolid,
        faKeySolid,
        faInboxInSolid,
        faInboxOutSolid,
        // faMailboxSolid,
        faMapMarkerSolid,
        faGlobeSolid,
        faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faCloudDownloadAlt, faUnlock,
        faEyeSolid,
        faCogSolid,
        faChartLineSolid, faFolderSolid, faFileCodeSolid, faFileAltSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faMinusSquare, faPaperclip, faArrowLeft,
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSolid,
        faCircleSolid, faCircleRegular,
        faCheckCircleSolid,
        faBoltSolid,
        faIdBadgeSolid,
        faAddressCardSolid,
        faLockSolid,
        faRedoSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faSyncAltRegular,
        faCopyRegular,
        faClockRegular,
        faFileCheckRegular,
        faClipboardListCheckRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        faEllipsisVRegular,
        faCalendarAltRegular,
        farCode);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
